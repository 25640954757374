import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import styled from "styled-components";
import moment from "moment";
import muiThemeable from "material-ui/styles/muiThemeable";
import reduce from "lodash/reduce";

import reject from "lodash/reject";
import filter from "lodash/filter";
import round from "lodash/round";
import toNumber from "lodash/toNumber";
import isFinite from "lodash/isFinite";
import { convertCurrency, formatCurrency } from "helpers/formattingHelpers";
import { fetchRegisteredClick as fetchRegisteredClickAction, fetchEmployersDetails as fetchEmployersDetailsAction, fetchEmployersUserBehaviourStats as fetchEmployersUserStatsAction } from "./employerActions";

import {
  FlexibleXYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  Hint
} from "react-vis";
import { PersonAdd, HourglassFull, AccountBalance, AttachMoney,Assessment,
  Terrain, Traffic,
  Subject, AccessTime, Payment, Person, People, School, Language, FastForward, ArrowDownward as ArrowDownwardIcon } from "@material-ui/icons";

import { selectEntityIdentifiers } from "../partners/selectors";
import {
  StatCard,
  TableGraph,
  StyledGridContainer,
  StyledGridItem,
  VaultCardHeaderIcon
} from "components";
import Grid from "../components/grids/Grid";
import DownloadButton from "../components/buttons/DashboardDownload";
import { requestGetImpactReports } from "./actions/reportActions";
import stateName from "../helpers/stateName";
import Table from "packages/mui-dashboard/components/Table/Table.jsx";
import Card from "packages/mui-dashboard/components/Card/Card.jsx";
import CardBody from "packages/mui-dashboard/components/Card/CardBody.jsx";

const HintDiv = styled.div`
  background: #f9e7bb;
  border-radius: 3px;
  border: 1px solid #edaf00;
  padding: 5px;
  color: #333;
  font-size: 10px;
  position: absolute;
  margin: -15px 0 0 -80px;
  width: 40px

  &::after {
    border-radius: 5px;
    border: 2px solid #a10066
    background: #a10066
    display: block;
    content: " ";
    height: 6px;
    width: 6px;
    top: 8px;
    left: 74px;
    position: absolute;
  }
`;

export class EmployerDashboard extends Component {
  componentDidMount() {
    const { employerID, fetchRegisteredClick, fetchEmployersDetails, fetchEmployersUserStats } = this.props;
    fetchRegisteredClick(employerID);
    fetchEmployersDetails(employerID);
    fetchEmployersUserStats(employerID);
  }

  state = {
    hoveredCell: false,
    showClicks: false,
    averagePaymentCell: false,
    activeCustomerCell: false,
    enrolledCustomerCell: false,
    payoffTimeCell: false,
    averageBalanceCell: false,
    totalBalanceCell: false
  };

  formatCurrency = (val, defaultValue) => {
    return isFinite(toNumber(val)) ? formatCurrency(val, true) : defaultValue;
  };

  filterEnrollmentData = enrollmentData => {
    const { entityIdentifiers } = this.props;

    const barGraphShortNames = {
      [`Enrolled ${entityIdentifiers.employeeIdentifier}s`]: "Enrolled",
      [`Active ${entityIdentifiers.employeeIdentifier}s`]: "Active",
      [`Loan-registered ${entityIdentifiers.employeeIdentifier}s`]: "Loan-registered",
      [`${entityIdentifiers.employeeIdentifier}s included in previous invoices`]: "Previously Invoiced",
    };

    return reduce(
      enrollmentData, (result, obj) => {
        if(!obj.barGraph || !obj.y) return result;
        result.push({ x: barGraphShortNames[obj.x], y: obj.y });

        // result.push({x: obj.x, y: obj.y})

        return result;
      },[]
    );
  };

  filterMonthlyAvgData = (avgMonthlyData) => {

    return reduce(
      avgMonthlyData,
      (result, obj) => {
        result.push({ x: obj.xFormat, y: obj.y });
        return result;
      },
      []
    );
  }

  filterAccountGoalData = accountGoalData => {
    return filter(accountGoalData, val => isFinite(val.y));
  };

  shouldDrawData = d => isFinite(d.y);

  calcActiveAccountsGoal = totalHeadCount => round(totalHeadCount * 0.05) || 0;

  calcPercentage = (numA, numB) => {
    if (!numB) {
      return "0";
    }

    const rounded = round((numA * 100) / numB);
    return !numA ? 0 : `${rounded}${rounded ? "%" : ""}`;
  };

  avgPayoffTime = numMonths => {
    if (!numMonths || numMonths === 0) {
      return "-";
    }
    let floatYears;
    //parseFloat(119/12).toPrecision(2) => 9.9 => correct
    //parseFloat(121/12).toPrecision(2) => 10 => incorrect
    //parseFloat(121/12).toPrecision(3) => 10.1 => correct
    if (numMonths < 120) {
      floatYears = parseFloat(numMonths / 12).toPrecision(2);
    } else {
      floatYears = parseFloat(numMonths / 12).toPrecision(3);
    }
    //If there is no fraction of a year, do not display the decimal
    //For example, display '10 years' and not '10.0' years
    if (numMonths % 12 === 0) {
      const intYears = parseInt(floatYears);
      if (intYears === 1) {
        return `${intYears} year`;
      }
      return `${intYears} years`;
    }
    //value contains fraction of a year
    return `${floatYears} years`;
  };

  render() {
    const { hoveredCell, activeCustomerCell, totalBalanceCell, enrolledCustomerCell, averageBalanceCell, averagePaymentCell,payoffTimeCell } = this.state;
    const {
      advisorStats,
      benefitStats,
      totalEmployees,
      payEnabled,
      isLoading,
      employer,
      requestGetImpactReports,
      employerID,
      entityIdentifiers,
      registeredClicksDataLoading,
      registeredClicksData,
      employersDetails,
      employersUserStatsDetails,
    } = this.props;
    const showFiveTwoNineReport = employersDetails && employersDetails.employersData && employersDetails.employersData.data.attributes.has_five_two_nine; 
    const advisorStatsRefinancingVisitCount = advisorStats.refinancing_visit_count
      ? advisorStats.refinancing_visit_count
      : 0;
    const advisorStatsLowerPaymentsVisitCount = advisorStats.lower_payments_visit_count
      ? advisorStats.lower_payments_visit_count
      : 0;
    const advisorStatsTotalEmployeesCount = advisorStats.total_employees_count
      ? advisorStats.total_employees_count
      : 0;
    // totalEmployees is the employer's arbitrarily-set total_employees
    const totalHeadCount = toNumber(
      totalEmployees || advisorStatsTotalEmployeesCount
    );


    const advisorStatsPayFasterVisitCount = advisorStats.pay_faster_visit_count
      ? advisorStats.pay_faster_visit_count
      : 0;
    const activeEmployees = advisorStats.active_employees_count
      ? advisorStats.active_employees_count
      : 0;
    const eligibleEmployees = advisorStats.eligible_employees_count
      ? advisorStats.eligible_employees_count
      : 0;
    const enrolledEmployees = advisorStats.enrolled_employees_count
      ? advisorStats.enrolled_employees_count
      : 0;
    const allFamilyCount = advisorStats.all_family_count
      ? advisorStats.all_family_count
      : 0;
    const advisorStatsTotalBalance = advisorStats.total_balance
      ? advisorStats.total_balance
      : 0;
    const advisorStatsAvgBalance = advisorStats.avg_balance
      ? advisorStats.avg_balance
      : 0;
    const advisorStatsAvgMonthlyPayment = advisorStats.avg_monthly_payment
      ? advisorStats.avg_monthly_payment
      : 0;
    const advisorStatsAvgPayoffTime = advisorStats.avg_payoff_time
      ? advisorStats.avg_payoff_time
      : 0;

    const totalDebt = this.formatCurrency(advisorStatsTotalBalance, true);
    const avgDebt = this.formatCurrency(advisorStatsAvgBalance, true);
    const avgPayment = this.formatCurrency(advisorStatsAvgMonthlyPayment, true);

    const avgPayoffTime = this.avgPayoffTime(advisorStatsAvgPayoffTime);

    const benefitStatsAvgSavingsWithBenefit = benefitStats.avg_savings_with_benefit
      ? benefitStats.avg_savings_with_benefit
      : 0;
    const avgSavingsPay = this.formatCurrency(
      benefitStatsAvgSavingsWithBenefit,
      true
    );
    const benefitStatsAvgTimeSavedWithBenefit = benefitStats.avg_time_saved_with_benefit
      ? benefitStats.avg_time_saved_with_benefit
      : 0;
    const avgTimeSavedPay = `${round(
      benefitStatsAvgTimeSavedWithBenefit || 0
    )} months`;
    const benefitStatsDistributedFunds = benefitStats.distributed_funds
      ? benefitStats.distributed_funds
      : 0;
    const totalCorpContr = this.formatCurrency(
      benefitStatsDistributedFunds,
      "-"
    );
    const activeAccountsGoal = this.calcActiveAccountsGoal(
      totalHeadCount,
      enrolledEmployees
    );

    const monthlyAvgBalance = advisorStats && advisorStats.monthly_avg_balance ? advisorStats.monthly_avg_balance : 0;

    const monthlyAvgPayment = advisorStats && advisorStats.monthly_avg_monthly_payment ? advisorStats.monthly_avg_monthly_payment : 0;

    const monthlyAvgPayoffTime = advisorStats && advisorStats.monthly_avg_payoff_time ? advisorStats.monthly_avg_payoff_time : 0;

    const monthlyTotalBalance = advisorStats && advisorStats.monthly_total_balance ? advisorStats.monthly_total_balance : 0;

    const monthlyGrowthActiveCustomers = advisorStats && advisorStats.monthly_growth_active_customers ? advisorStats.monthly_growth_active_customers : 0;

    const monthlyGrowthEnrolledCustomers =  advisorStats && advisorStats.monthly_growth_enrolled_customers ? advisorStats.monthly_growth_enrolled_customers : 0;

    const eligibleForMatch = advisorStats && advisorStats.eligible_for_match ? advisorStats.eligible_for_match : 0;
    const eligibleForPay = advisorStats && advisorStats.eligible_for_pay ? advisorStats.eligible_for_pay : 0;
    const eligibleForTeacherLoanForgiveness = advisorStats && advisorStats.eligible_for_tlf ? advisorStats.eligible_for_tlf : 0;
    const eligibleForPublicLoanForgiveness = advisorStats && advisorStats.eligible_for_pslf ? advisorStats.eligible_for_pslf : 0;

    const monthlyAvgBalanceDataSet = [], monthlyAvgPaymentDataSet = [], monthlyAvgPayoffTimeDataSet = [], monthlyTotalBalanceDataSet = [], monthlyGrowthActiveCustomersDataSet = [],monthlyGrowthEnrolledCustomersDataSet = [];

    const topUserContentStats = employersUserStatsDetails && employersUserStatsDetails.stats ? employersUserStatsDetails.stats.top_content : [];
    const topUserCalculatorStats = employersUserStatsDetails && employersUserStatsDetails.stats ? employersUserStatsDetails.stats.top_used_calculators : [];
    const topTrafficSource = employersUserStatsDetails && employersUserStatsDetails.stats ? employersUserStatsDetails.stats.traffic_source : [];

    const pushDataIntoMonthlyGraph = (monthlyDataArr, monthlyDataSet) => {
      for (const x in monthlyDataSet) {
        monthlyDataArr.push({
          x:x,
          y: convertCurrency(monthlyDataSet[x], true) || 0,
          barGraph: true,
          xFormat: `${moment(x).format('MMM YY')}`,
          yFormat: formatCurrency(monthlyDataSet[x], true) || 0
        })
      }
    }


    const hasTableData = (topUserData) => {
      if (!!topUserData && topUserData.length > 0) {
          if (!!topUserData[0]) {
              return !!topUserData[0].activeUsers && !!topUserData[0].screenPageViews && !!topUserData[0].pageTitle;

          }
      }
    }

    
    const hasTrafficdata = (trafficData) => {
      if (!!trafficData && trafficData.length > 0) {
        if (!!trafficData[0]) {
          return !!trafficData[0].session_source && !!trafficData[0].session_medium && !!trafficData[0].total
        }
      }
    }


    const pushDataIntoMonthlyAvgGraph = (monthlyDataArr, monthlyDataSet) => {
      for (const x in monthlyDataSet) {
        monthlyDataArr.push({
          x:x,
          y:monthlyDataSet[x] || 0,
          barGraph: true,
          xFormat:`${moment(x).format('MMM YY')}`,
          yFormat:`${monthlyDataSet[x]} months` || 0
        })
      }
    }

    const pushDataIntoMonthlyGraphCustomers = (monthlyDataArr, monthlyDataSet) => {
        for (const x in monthlyDataSet) {
          monthlyDataArr.push({
            x:x,
            y:monthlyDataSet[x]['absolute'],
            barGraph: true,
            xFormat: `${moment(x).format('MMM YY')}`,
            yFormat: `${monthlyDataSet[x]['absolute']} (${monthlyDataSet[x]['percent']}%)`
          });
        }
      }

    pushDataIntoMonthlyGraph(monthlyAvgBalanceDataSet, monthlyAvgBalance);
    pushDataIntoMonthlyGraph(monthlyAvgPaymentDataSet, monthlyAvgPayment);
    pushDataIntoMonthlyAvgGraph(monthlyAvgPayoffTimeDataSet, monthlyAvgPayoffTime);
    pushDataIntoMonthlyGraph(monthlyTotalBalanceDataSet, monthlyTotalBalance);

    pushDataIntoMonthlyGraphCustomers(monthlyGrowthActiveCustomersDataSet, monthlyGrowthActiveCustomers);
    pushDataIntoMonthlyGraphCustomers(monthlyGrowthEnrolledCustomersDataSet, monthlyGrowthEnrolledCustomers);


    const enrollmentData = [
      { x: `Total ${entityIdentifiers.employeeIdentifier}s`, y: totalHeadCount,barGraph: false,
      title:'tTip-All Employees'
    },
      {
        x: `Active ${entityIdentifiers.employeeIdentifier}s`,
        y: eligibleEmployees,
        barGraph: true,
        title:'tTip-Employees with an active and effective account on the present date'
      },
      {
        x: "Eligible for Pay",
        y: eligibleForPay || 0,
        barGraph: false,
        title:'tTip-Employees with Flat, Vested or CustomBenefit services'
      },
      {
        x: "Eligible for Match",
        y: eligibleForMatch || 0,
        barGraph: false,
        title:'tTip-Employees with Genius Match services'
      },
      {
        x: "Eligible for Teacher Loan Forgiveness",
        y: eligibleForTeacherLoanForgiveness || 0,
        barGraph: false,
        title:'tTip-Employees eligible for Teacher Loan Forgiveness program'
      },
      {
        x: "Eligible for Public Loan Forgiveness",
        y: eligibleForPublicLoanForgiveness || 0,
        barGraph: false,
        title:'tTip-Employees eligible for Public Loan Forgiveness program'
      },
      {
        x: `Enrolled ${entityIdentifiers.employeeIdentifier}s`,
        y: enrolledEmployees || 0,
        // z: this.calcPercentage(enrolledEmployees, totalHeadCount),
        barGraph: true,
        title:'tTip-Active employees who accepted Vault invitation'
      },
      {
        x: `Loan-registered ${entityIdentifiers.employeeIdentifier}s`,
        y: activeEmployees,
        // z: this.calcPercentage(activeEmployees, totalHeadCount),
        barGraph: true,
        title:'tTip-Enrolled Employees who registered their loan info'
      },
      {
        x: `${entityIdentifiers.employeeIdentifier}s included in previous invoices`,
        y: benefitStats.pay_enrolled_employees_count || 0,
        // z: this.calcPercentage(activeEmployees, totalHeadCount),
        barGraph: true,
        title:'tTip-Employees included to the latest invoices'
      },
   
    ];

    if (allFamilyCount > 0) {
      enrollmentData.push({ x: "Invited Family Members", y: allFamilyCount });
    }

    const vaultPayData = [
      // {
      //   x: `Active ${entityIdentifiers.employeeIdentifier}s with Pay`,
      //   y: benefitStats.pay_enrolled_employees_count || 0,
      //   title:'tTip-Employers with available Flat, Genius, Vested services'
      // },
      {
        x: `Average ${entityIdentifiers.employeeIdentifier} Pay Savings`,
        y: avgSavingsPay,
        title:'tTip-Average employee money savings with paying extra amount for all employees'
      },
      {
        x: `Average ${entityIdentifiers.employeeIdentifier} Pay Time Saved (mos.)`,
        y: avgTimeSavedPay,
        title:'tTip-Average employee time savings with paying extra amount for all employees'
      },
      { x: "Total Contributions Distributed", y: totalCorpContr,
      title:'tTip-Total disbursement sum'
    }
    ];

    const visitorStats = [
      {
        x: `Active visitors`,
        y: employersUserStatsDetails && employersUserStatsDetails.stats.active_visitors ? employersUserStatsDetails.stats.active_visitors : '0',
        title: "tTip-Number of distinct employees who's session lasted longer than 10 seconds"
      },
      {
        x: `Average time spent in Vault tool`,
        y: employersUserStatsDetails && employersUserStatsDetails.stats.avg_engagement ? employersUserStatsDetails.stats.avg_engagement + ' mins': '0',
        title: "tTip-Average amount of time Vault website was in foreground of employee's device"
      },
      {
        x: `#/% of participants viewing articles`,
        y: employersUserStatsDetails && employersUserStatsDetails.stats.article_visitors? `${employersUserStatsDetails.stats.article_visitors.absolute} (${employersUserStatsDetails.stats.article_visitors.percent} %)` : '0 %',
        title: "tTip-% employees who viewed Match FAQ, FAQ, Payroll Deduction, Benefits of 529 Plan, How Do I Choose The 529 Plan That Is Best For Me?, PSLF, 529 Tax Benefits By State."
      },
      {
        x: `#/% of participants viewing calculators`,
        y: employersUserStatsDetails && employersUserStatsDetails.stats.calculator_visitors ? `${employersUserStatsDetails.stats.calculator_visitors.absolute} (${employersUserStatsDetails.stats.calculator_visitors.percent} %)` : '0 %',
        title: "tTip-% employees who viewed calculators"
      },
      {
        x: `Average time spent in Vault session`,
        y:  employersUserStatsDetails && employersUserStatsDetails.stats.avg_session_duration ? employersUserStatsDetails.stats.avg_session_duration+' mins': '0',
        title: "tTip-Average duration of employee's session"
      },

    ];

    const fiveTwoNinePlaceholder = [
      {
        x: "Contributees",
        y: "0"
      },
      {
        x: "Contributors",
        y: "0"
      },
      {
        x: "Average Contribution",
        y: "$0"
      },
      {
        x: "529 users",
        y: "0"
      },
      {
        x: "Number of user clicks on the state map",
        y: "0"
      }
    ];

    const regClickDataToArray = Object.entries(
      registeredClicksData !== null && registeredClicksData.clickStats
    );

    const registeredClickData = [
      regClickDataToArray
        .map(e => {
          if (e[0] === "average_contribution") {
            return {
              x: stateName[e[0]],
              y: "$" + e[1]
            };
          } else if (e[1] === null) {
            return {
              x: stateName[e[0]],
              y: "0"
            };
          } else {
            return {
              x: stateName[e[0]],
              y: e[1]
            };
          }
        })
        .filter(e => {
          return e.x !== "Date";
        })
    ];

    const stateData = registeredClickData[0].filter(e => {
      return e.x && e.x.includes(":");
    });
    const clicksData = registeredClickData[0].filter(e => {
      return e.x && !e.x.includes(":");
    });

    // divide array into four parts to map it seperately
    const quarterIndex = Math.ceil(stateData.length / 4);
    const stateColOne = stateData.splice(0, quarterIndex);
    const stateColTwo = stateData.splice(0, quarterIndex);
    const stateColThree = stateData.splice(0, quarterIndex);
    const stateColFour = stateData.splice(0, quarterIndex, -quarterIndex);
    const regClicksData = [
      [...stateColOne],
      [...stateColTwo],
      [...stateColThree],
      [...stateColFour]
    ];

    const impactReportFileName = `${
      employer ? employer.name : ""
    }_ImpactReport_${moment().format("MM/DD/YYYY")}`;

    return (
      <React.Fragment>
        <StyledGridContainer>
          <StyledGridItem xs={12}>
            <Grid container justify="flex-end">
              <Grid item>
                {/*<Link href={`#/employers/${employerID}/match_reports`}>
                  Link
                </Link>*/}
                <DownloadButton
                  onClick={() =>
                    requestGetImpactReports(employerID, impactReportFileName)
                  }
                >
                  Impact Report
                </DownloadButton>
              </Grid>
            </Grid>
          </StyledGridItem>
        </StyledGridContainer>
        <StyledGridContainer>
          <StyledGridItem xs={12} sm={6} md={6} lg={3}>
            <StatCard
              title={`Total ${entityIdentifiers.employeeIdentifier} Debt Balance`}
              data={totalDebt}
              icon={<AttachMoney />}
              footer="Advisor"
              isLoading={isLoading}
              color="info"
              toolTip="Total debt upon all employees"
            />
          </StyledGridItem>
          <StyledGridItem xs={12} sm={6} md={6} lg={3}>
            <StatCard
              title={`Average ${entityIdentifiers.employeeIdentifier} Debt Balance`}
              data={avgDebt}
              icon={<Person />}
              footer="National Total Debt Average: $29,650"
              isLoading={isLoading}
              color="rose"
              toolTip="Average debt upon all employees"
            />
          </StyledGridItem>
          <StyledGridItem xs={12} sm={6} md={6} lg={3}>
            <StatCard
              title={`Average ${entityIdentifiers.employeeIdentifier} Monthly Payment`}
              data={avgPayment}
              icon={<Payment />}
              footer="National Monthly Payment Average: $393"
              isLoading={isLoading}
              color="success"
              toolTip="Average monthly payment upon all employees"
            />
          </StyledGridItem>
          <StyledGridItem xs={12} sm={6} md={6} lg={3}>
            <StatCard
              title={`Average ${entityIdentifiers.employeeIdentifier} Payoff`}
              data={avgPayoffTime}
              icon={<AccessTime />}
              footer="Advisor"
              isLoading={isLoading}
              color="warning"
              toolTip="Average time of debt payoff"
            />
          </StyledGridItem>
        </StyledGridContainer>
        <StyledGridContainer>
          <StyledGridItem xs={12}>
            <TableGraph
              title="Enrollment"
              isLoading={isLoading}
              icon={<School />}
              tableData={enrollmentData.map(val => [val.x, val.y, val.title])}
              hover={true}
              graph={
                <FlexibleXYPlot
                  xType="ordinal"
                  fill="#5372FC"
                  stroke="#5372FC"
                  stackBy="y"
                  // yDomain={[0, totalHeadCount]}
                  // margin={{ bottom: 65 }}
                >
                  <VerticalGridLines />
                  <HorizontalGridLines />
                  <XAxis
                    // title={totalHeadCount === 0 ? "No Data" : ""}
                    // tickLabelAngle={-15}
                  />
                  <YAxis
                    title={`${entityIdentifiers.employeeIdentifier}s stats`}
                    // hideTicks={totalHeadCount === 0}
                  />
                  <VerticalBarSeries
                    data={this.filterEnrollmentData(enrollmentData)}
                    getNull={this.shouldDrawData}
                    barWidth={0.25}
                    onValueMouseOver={v => {
                      this.setState({ hoveredCell: v });
                    }}
                    onValueMouseOut={() =>
                      this.setState({ hoveredCell: false })
                    }
                  />
                  {/* {activeAccountsGoal ? (
                    <Hint
                      value={{
                        x: `Enrolled ${entityIdentifiers.employeeIdentifier}s`,
                        y: activeAccountsGoal
                      }}
                    >
                      <HintDiv>{`Target: 5% (${activeAccountsGoal})`}</HintDiv>
                    </Hint>
                  ) : (
                    <React.Fragment />
                  )} */}

                  {hoveredCell && (
                    <Hint
                      value={hoveredCell}
                      align={{
                        horizontal: Hint.ALIGN.AUTO,
                        vertical: Hint.ALIGN.BOTTOM
                      }}
                    >
                      <div className="rv-hint__content">{`${hoveredCell.y} Employees`}</div>
                    </Hint>
                  )}
                </FlexibleXYPlot>
              }
            />
          </StyledGridItem>
        </StyledGridContainer>

        <StyledGridContainer>
          <StyledGridItem xs={12}>
            <TableGraph
              title="Loan-registered Employees"
              isLoading={isLoading}
              icon={<People />}
              iconColor='danger'
              tableData={monthlyGrowthActiveCustomersDataSet.map(val => [val.xFormat, val.yFormat])}
              headerTooltip="Monthly growth of employees who set up their Loan Info"

              graph={
                <FlexibleXYPlot
                  xType="ordinal"
                  fill="#E8403C"
                  stroke="#E8403C"
                  stackBy="y"
                  // yDomain={[0, totalHeadCount]}
                  // margin={{ bottom: 65 }}
                >
                  <VerticalGridLines />
                  <HorizontalGridLines />
                  <XAxis
                    // title={totalHeadCount === 0 ? "No Data" : ""}
                    // tickLabelAngle={-15}
                  />
                  <YAxis
                    title={`Loan-registered Employees`}
                    // hideTicks={totalHeadCount === 0}
                  />
                  <VerticalBarSeries
                    data={this.filterMonthlyAvgData(monthlyGrowthActiveCustomersDataSet)}
                    getNull={this.shouldDrawData}
                    barWidth={0.35}
                    onValueMouseOver={v => {
                      this.setState({ activeCustomerCell: v });
                    }}
                    onValueMouseOut={() =>
                      this.setState({ activeCustomerCell: false })
                    }
                  />

                  {activeCustomerCell && (
                    <Hint
                      value={activeCustomerCell}
                      align={{
                        horizontal: Hint.ALIGN.AUTO,
                        vertical: Hint.ALIGN.BOTTOM
                      }}
                    >
                      <div className="rv-hint__content">{`${activeCustomerCell.y}`}</div>
                    </Hint>
                  )}
                </FlexibleXYPlot>
              }
            />
          </StyledGridItem>
        </StyledGridContainer>

        <StyledGridContainer>
          <StyledGridItem xs={12}>
            <TableGraph
              title="Enrolled Employees"
              isLoading={isLoading}
              icon={<PersonAdd/> }
              iconColor='danger'
              tableData={monthlyGrowthEnrolledCustomersDataSet.map(val => [val.xFormat, val.yFormat])}
              headerTooltip="Active employees who accepted Vault invitation"

              graph={
                <FlexibleXYPlot
                  xType="ordinal"
                  fill="#E8403C"
                  stroke="#E8403C"
                  stackBy="y"
                  // yDomain={[0, totalHeadCount]}
                  // margin={{ bottom: 65 }}
                >
                  <VerticalGridLines />
                  <HorizontalGridLines />
                  <XAxis
                    // title={totalHeadCount === 0 ? "No Data" : ""}
                    // tickLabelAngle={-15}
                  />
                  <YAxis
                    title={`Enrolled Employees`}
                    // hideTicks={totalHeadCount === 0}
                  />
                  <VerticalBarSeries
                    data={this.filterMonthlyAvgData(monthlyGrowthEnrolledCustomersDataSet)}
                    getNull={this.shouldDrawData}
                    barWidth={0.35}
                    onValueMouseOver={v => {
                      this.setState({ enrolledCustomerCell: v });
                    }}
                    onValueMouseOut={() =>
                      this.setState({ enrolledCustomerCell: false })
                    }
                  />

                  {enrolledCustomerCell && (
                    <Hint
                      value={enrolledCustomerCell}
                      align={{
                        horizontal: Hint.ALIGN.AUTO,
                        vertical: Hint.ALIGN.BOTTOM
                      }}
                    >
                      <div className="rv-hint__content">{`${enrolledCustomerCell.y}`}</div>
                    </Hint>
                  )}
                </FlexibleXYPlot>
              }
            />
          </StyledGridItem>
        </StyledGridContainer>

        <StyledGridContainer>
          <StyledGridItem xs={12}>
            <TableGraph
              title="Average Balance"
              isLoading={isLoading}
              icon={<AccountBalance />}
              iconColor='rose'
              tableData={monthlyAvgBalanceDataSet.map(val => [val.xFormat, val.yFormat])}
              headerTooltip="Monthly average debt upon all employees"

              graph={
                <FlexibleXYPlot
                  xType="ordinal"
                  fill="#DC2265"
                  stroke="#DC2265"
                  stackBy="y"
                  // yDomain={[0, totalHeadCount]}
                  margin={{ left: 50 }}
                >
                  <VerticalGridLines />
                  <HorizontalGridLines />
                  <XAxis
                    // title={totalHeadCount === 0 ? "No Data" : ""}
                    // tickLabelAngle={-15}
                  />
                  <YAxis
                    title={`Average Balance`}
                    // hideTicks={totalHeadCount === 0}
                  />
                  <VerticalBarSeries
                    data={this.filterMonthlyAvgData(monthlyAvgBalanceDataSet)}
                    getNull={this.shouldDrawData}
                    barWidth={0.35}
                    onValueMouseOver={v => {
                      this.setState({ averageBalanceCell: v });
                    }}
                    onValueMouseOut={() =>
                      this.setState({ averageBalanceCell: false })
                    }
                  />

                  {averageBalanceCell && (
                    <Hint
                      value={averageBalanceCell}
                      align={{
                        horizontal: Hint.ALIGN.AUTO,
                        vertical: Hint.ALIGN.BOTTOM
                      }}
                    >
                      <div className="rv-hint__content">{formatCurrency(averageBalanceCell.y)}</div>
                    </Hint>
                  )}
                </FlexibleXYPlot>
              }
            />
          </StyledGridItem>
        </StyledGridContainer>

        <StyledGridContainer>
          <StyledGridItem xs={12}>
            <TableGraph
              title="Total Balance"
              isLoading={isLoading}
              icon={<AccountBalance />}
              tableData={monthlyTotalBalanceDataSet.map(val => [val.xFormat, val.yFormat])}
              headerTooltip="Monthly total debt upon all employees"

              graph={
                <FlexibleXYPlot
                  xType="ordinal"
                  fill="#0DB5CA"
                  stroke="#0DB5CA"
                  stackBy="y"
                  // yDomain={[0, totalHeadCount]}
                  margin={{ left: 60 }}
                >
                  <VerticalGridLines />
                  <HorizontalGridLines />
                  <XAxis
                    // title={totalHeadCount === 0 ? "No Data" : ""}
                    // tickLabelAngle={-15}
                  />
                  <YAxis
                    title={`Total Balance`}
                    // hideTicks={totalHeadCount === 0}
                  />
                  <VerticalBarSeries
                    data={this.filterMonthlyAvgData(monthlyTotalBalanceDataSet)}
                    getNull={this.shouldDrawData}
                    barWidth={0.35}
                    onValueMouseOver={v => {
                      this.setState({ totalBalanceCell: v });
                    }}
                    onValueMouseOut={() =>
                      this.setState({ totalBalanceCell: false })
                    }
                  />

                  {totalBalanceCell && (
                    <Hint
                      value={totalBalanceCell}
                      align={{
                        horizontal: Hint.ALIGN.AUTO,
                        vertical: Hint.ALIGN.BOTTOM
                      }}
                    >
                      <div className="rv-hint__content">{formatCurrency(totalBalanceCell.y)}</div>
                    </Hint>
                  )}
                </FlexibleXYPlot>
              }
            />
          </StyledGridItem>
        </StyledGridContainer>

        <StyledGridContainer>
          <StyledGridItem xs={12}>
            <TableGraph
              title="Average Payment"
              isLoading={isLoading}
              icon={<AttachMoney />}
              iconColor='success'
              tableData={monthlyAvgPaymentDataSet.map(val => [val.xFormat, val.yFormat])}
              headerTooltip="Monthly average payment upon all employees"

              graph={
                <FlexibleXYPlot
                  xType="ordinal"
                  fill="#52AB56"
                  stroke="#52AB56"
                  stackBy="y"
                  // yDomain={[0, totalHeadCount]}
                  margin={{ left: 45 }}
                >
                  <VerticalGridLines />
                  <HorizontalGridLines />
                  <XAxis
                    // title={totalHeadCount === 0 ? "No Data" : ""}
                    // tickLabelAngle={-15}
                  />
                  <YAxis
                    title={`Average Payment`}
                    // hideTicks={totalHeadCount === 0}
                  />
                  <VerticalBarSeries
                    data={this.filterMonthlyAvgData(monthlyAvgPaymentDataSet)}
                    getNull={this.shouldDrawData}
                    barWidth={0.35}
                    onValueMouseOver={v => {
                      this.setState({ averagePaymentCell: v });
                    }}
                    onValueMouseOut={() =>
                      this.setState({ averagePaymentCell: false })
                    }
                  />

                  {averagePaymentCell && (
                    <Hint
                      value={averagePaymentCell}
                      align={{
                        horizontal: Hint.ALIGN.AUTO,
                        vertical: Hint.ALIGN.BOTTOM
                      }}
                    >
                      <div className="rv-hint__content">{formatCurrency(averagePaymentCell.y)}</div>
                    </Hint>
                  )}
                </FlexibleXYPlot>
              }
            />
          </StyledGridItem>
        </StyledGridContainer>

        <StyledGridContainer>
          <StyledGridItem xs={12}>
            <TableGraph
              title="Average Payoff Time"
              isLoading={isLoading}
              icon={<HourglassFull />}
              iconColor='warning'
              tableData={monthlyAvgPayoffTimeDataSet.map(val => [val.xFormat, val.yFormat])}
              headerTooltip="Monthly time of debt payoff upon all employees"

              graph={
                <FlexibleXYPlot
                  xType="ordinal"
                  fill="#FE9C17"
                  stroke="#FE9C17"
                  stackBy="y"
                  // yDomain={[0, totalHeadCount]}
                  // margin={{ bottom: 65 }}
                >
                  <VerticalGridLines />
                  <HorizontalGridLines />
                  <XAxis
                    // title={totalHeadCount === 0 ? "No Data" : ""}
                    // tickLabelAngle={-15}
                  />
                  <YAxis
                    title={`Average Payoff Time`}
                    // hideTicks={totalHeadCount === 0}
                  />
                  <VerticalBarSeries
                    data={this.filterMonthlyAvgData(monthlyAvgPayoffTimeDataSet)}
                    getNull={this.shouldDrawData}
                    barWidth={0.35}
                    onValueMouseOver={v => {
                      this.setState({ payoffTimeCell: v });
                    }}
                    onValueMouseOut={() =>
                      this.setState({ payoffTimeCell: false })
                    }
                  />

                  {payoffTimeCell && (
                    <Hint
                      value={payoffTimeCell}
                      align={{
                        horizontal: Hint.ALIGN.AUTO,
                        vertical: Hint.ALIGN.BOTTOM
                      }}
                    >
                      <div className="rv-hint__content">{`${payoffTimeCell.y} months`}</div>
                    </Hint>
                  )}
                </FlexibleXYPlot>
              }
            />
          </StyledGridItem>
        </StyledGridContainer>

        <StyledGridContainer>
          <StyledGridItem xs={12}>
            <TableGraph
              title="Visitor Stats"
              icon={<Assessment />}
              tableData={visitorStats.map(val => [val.x, val.y, val.title])}
              isLoading={isLoading}
              hover={true}            />
          </StyledGridItem>
        </StyledGridContainer>

        {hasTableData(topUserContentStats) && (

        <StyledGridContainer>
            <StyledGridItem xs={12}>
              <Card>
                <VaultCardHeaderIcon
                  icon={<Terrain />}
                  title="Top Content"
                  color="info"
                  headerTooltip="Top 3 page ranked by where employees spent the most time."
                />

                <CardBody>
                  <StyledGridItem xs>
                      <Table
                        tableData={topUserContentStats.map(val => [val.pageTitle ? val.pageTitle : '-', val.pagePath ? val.pagePath: '-', val["calcMetric:avg_engagement"] ? val["calcMetric:avg_engagement"]+' mins' :'0 min' ])}
                        tableHead={['Page Title','Page Path', 'Avg. time of usage per customer']} 
                      />

                  </StyledGridItem>
                </CardBody>
              </Card>
            </StyledGridItem>
          </StyledGridContainer>
        )}


        {hasTableData(topUserCalculatorStats) && (

          <StyledGridContainer>
            <StyledGridItem xs={12}>
              <Card>
                <VaultCardHeaderIcon
                  icon={<Subject />}
                  title="Top Calculators"
                  color="info"
                  headerTooltip="Calculators ranked by where customer spent the most time."
                />

                <CardBody>
                  <StyledGridItem xs>
                      <Table
                        tableData={topUserCalculatorStats.map(val => [val.pageTitle ? val.pageTitle : '-', val["calcMetric:avg_engagement"] ? val["calcMetric:avg_engagement"]+' mins' :'0 min' ])}
                        tableHead={['Type', 'Avg. time of usage per customer']} 
                      />

                  </StyledGridItem>
                </CardBody>
              </Card>
            </StyledGridItem>
          </StyledGridContainer>
        )}

{hasTrafficdata(topTrafficSource) && (

<StyledGridContainer>
  <StyledGridItem xs={12}>
    <Card>
      <VaultCardHeaderIcon
        icon={<Traffic />}
        title="Traffic Source"
        color="info"
        headerTooltip="# of visits coming from the particular traffic source (% of the total visits)."
      />

      <CardBody>
        <StyledGridItem xs>
            <Table
              tableData={topTrafficSource.map(val => [val.session_source ? val.session_source : '-', val.session_medium ? val.session_medium: '-', val.absolute ? val.absolute + ' visits' + ' (' + val.percent + '%)'  :'0' ])}
              tableHead={['Source','Medium', 'Visits']} 
            />
         
        </StyledGridItem>
      </CardBody>
    </Card>
  </StyledGridItem>
</StyledGridContainer>
)}




        <StyledGridContainer>
          <StyledGridItem xs={12} sm={6} md={6} lg={3}>
            <StatCard
              title={`Visited Lower Payment`}
              data={advisorStatsLowerPaymentsVisitCount || "-"}
              icon={<ArrowDownwardIcon />}
              footer={`Advisor ${this.calcPercentage(
                advisorStatsLowerPaymentsVisitCount,
                activeEmployees
              )}`}
              isLoading={isLoading}
              color="success"
            />
          </StyledGridItem>
          <StyledGridItem xs={12} sm={6} md={6} lg={3}>
            <StatCard
              title={`Visited Refinance`}
              data={advisorStatsRefinancingVisitCount.toString() || ""}
              icon={<AttachMoney />}
              footer={`Advisor ${this.calcPercentage(
                advisorStatsRefinancingVisitCount,
                activeEmployees
              )}`}
              isLoading={isLoading}
              color="success"
            />
          </StyledGridItem>
          <StyledGridItem xs={12} sm={6} md={6} lg={3}>
            <StatCard
              title={`Visited Pay Faster`}
              data={advisorStatsPayFasterVisitCount || "-"}
              icon={<FastForward />}
              footer={`Advisor ${this.calcPercentage(
                advisorStatsPayFasterVisitCount,
                activeEmployees
              )}`}
              isLoading={isLoading}
              color="success"
            />
          </StyledGridItem>
        </StyledGridContainer>

        {payEnabled && (
          <StyledGridContainer>
            <StyledGridItem xs={12}>
              <TableGraph
                title="Vault Pay"
                icon={<Language />}
                tableData={vaultPayData.map(val => [val.x, val.y, val.title])}
                isLoading={isLoading}
                hover={true}
              />
            </StyledGridItem>
          </StyledGridContainer>
        )}

        {showFiveTwoNineReport === true && (
          <StyledGridContainer>
            <StyledGridItem xs={12}>
              <Card>
                <VaultCardHeaderIcon
                  icon={<Language />}
                  title="529"
                  color="info"
                />

                <CardBody>
                  <StyledGridItem xs>
                    {clicksData.length <= 1 ?
                      <Table
                        tableData={fiveTwoNinePlaceholder.map(val => [val.x, val.y])}
                      />
                      :
                      <>
                        <Table
                          tableData={clicksData
                            .filter(val => !val.x.startsWith("Number of"))
                            .map(val => [val.x, val.y])}
                        />
                        <div style={{ cursor: "pointer" }}>
                          <Table
                            clickHandler={() =>
                              this.setState(prevState => ({
                                showClicks: !prevState.showClicks
                              }))
                            }
                            tableData={clicksData
                              .filter(val => val.x.startsWith("Number of"))
                              .map(val => [val.x, val.y])}
                          />
                        </div>
                      </>
                    }
                  </StyledGridItem>
                  <br></br>
                  {this.state.showClicks ? (
                    <StyledGridContainer justify="space-between">
                      {regClicksData.map((e, i) => {
                        return (
                          <StyledGridItem key={i} xs>
                            <Table tableData={e.map(val => [val.x, val.y])} />
                          </StyledGridItem>
                        );
                      })}
                    </StyledGridContainer>
                  ) : (
                    ""
                  )}
                </CardBody>
              </Card>
            </StyledGridItem>
          </StyledGridContainer>
        )}
      </React.Fragment>
    );
  }
}

EmployerDashboard.propTypes = {
  advisorStats: PropTypes.shape({
    avg_balance: PropTypes.number,
    avg_balance_for_active_employees_in_system: PropTypes.number,
    total_balance: PropTypes.number,
    avg_monthly_payment: PropTypes.number,
    avg_monthly_payment_for_active_employees_in_system: PropTypes.number,
    lower_payments_visit_count: PropTypes.number,
    pay_faster_visit_count: PropTypes.number,
    refinancing_visit_count: PropTypes.number,
    active_employees_count: PropTypes.number,
    enrolled_employees_count: PropTypes.number,
    eligible_employees_count: PropTypes.number,
    all_family_count: PropTypes.number,
    total_employees_count: PropTypes.number
  }),
  benefitStats: PropTypes.shape({
    avg_savings_with_benefit: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    avg_time_saved_with_benefit: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    distributed_funds: PropTypes.number
  }),
  payEnabled: PropTypes.bool,
  totalEmployees: PropTypes.number,
  isLoading: PropTypes.bool.isRequired
};

EmployerDashboard.defaultProps = {
  advisorStats: {},
  benefitStats: {},
  payEnabled: false,
  totalEmployees: 0
};

function mapStateToProps(state) {
  const entityIdentifiers = selectEntityIdentifiers(state);
  return {
    entityIdentifiers,
    registeredClicksDataLoading: state.registeredClicksStats.isFetching,
    registeredClicksData: state.registeredClicksStats.data,
    employersDetails: state.employersDetails.data,
    employersUserStatsDetails: state.employerUserStats.data
  };
}

const enhance = compose(
  muiThemeable(),
  connect(
    mapStateToProps,
    {
      requestGetImpactReports,
      fetchRegisteredClick: fetchRegisteredClickAction,
      fetchEmployersDetails: fetchEmployersDetailsAction,
      fetchEmployersUserStats: fetchEmployersUserStatsAction
    }
  )
);

export default enhance(EmployerDashboard);
